import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }

  ${({ count, direction }) => {
    if (count) {
      let directionCss =
        direction === "row"
          ? ``
          : `
            max-width: calc(100% / ${count});
            padding: 0 10px;
        `;
      return `
            ${directionCss}
            @media (max-width: 768px) {
                max-width: 100%!important;
                padding: 0;
              }
        `;
    }
  }}
`;

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  ${({ added, bgColor, addedColor, hoverColor }) => {
    if (added) {
      return `
        & > div {
            background-color: ${addedColor}!important;
        }
        `;
    }

    return `
    & > div {
        background-color: ${bgColor};
    }
    &:hover {
        & > div {
            background-color: ${hoverColor};
        }
    }`;
  }}
`;

export const NoChildren = styled.div`
  padding: 50px;
  text-align: center;
  font-style: italic;
  font-size: 12px;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  background: ${({ headingColor }) =>
    headingColor ? headingColor : "#001C72"};
  font-family: "Neuzeit Grotesk", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  border-radius: 4px 4px 0 0;

  @media (max-width: 991px) {
    border-radius: 0 0 4px 4px;
  }
`;

export const Tick = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  z-index: 1;
  margin-left: 12px;
  margin-right: 8px;
  background: ${({ added }) => (added ? "#001C72" : "#ffffff")};
  cursor: pointer;
  border-radius: ${({ tickBoxRadius }) =>
    tickBoxRadius ? `${tickBoxRadius}!important` : "4px"};
  border: 1px solid transparent;

  & > img {
    width: 12px;
  }

  ${({ added, tickBoxColor, tickBoxColorAdded }) => {
    if (added) {
      return `border: 1px solid ${tickBoxColorAdded};`;
    } else {
      return `border: 1px solid ${tickBoxColor};`;
    }
  }}

  ${({ added, tickBoxColor, tickBoxColorAdded }) => {
    if (added) {
      return `
            &:after { 
                background: ${tickBoxColorAdded || tickBoxColor}!important;
            }`;
    } else {
      return ``;
    }
  }}
`;
