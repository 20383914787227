import React, { useContext, useState } from "react";
import * as S from "./ToggleContainerV1.styles";
import Context from "../../context/Context";
import { combineCartItems } from "../../utils/upsellCart";
import { Builder } from "@builder.io/react";

const ToggleContainerV1 = props => {
  const {
    children,
    bgColor,
    headingColor,
    headingActiveColor,
    addedColor,
    hoverColor,
    offerIndex,
    heading,
    showTickBox,
    tickBoxColor,
    tickBoxRadius
  } = props;
  const { selectorData, upsellCart, setUpsellCart } = useContext(Context);

  const [added, setAdded] = useState(false);

  const noChildren = () => {
    return <S.NoChildren>insert components here</S.NoChildren>;
  };

  const getCurrentOrder = () => {
    const { offers } = selectorData;
    const currentOrder = offers[offerIndex] || {};
    Object.assign(currentOrder, { offerIndex: offerIndex });
    return currentOrder;
  };

  const addToCart = () => {
    const cartItems = combineCartItems(upsellCart, getCurrentOrder());
    setUpsellCart(cartItems);
  };

  const removeFromCart = () => {
    const newCart = upsellCart.filter(({ variantId }) => {
      const { variantId: orderId } = getCurrentOrder();
      return variantId !== orderId;
    });
    setUpsellCart(newCart);
  };

  const handleClick = () => {
    if (Builder.isEditing) return;

    setAdded(!added);
    if (added) return removeFromCart();

    addToCart();
  };

  return (
    <S.Wrapper onClick={handleClick}>
      <S.Heading headingColor={added ? headingActiveColor : headingColor}>
        {showTickBox && (
          <S.Tick
            added={added}
            tickBoxRadius={tickBoxRadius}
            tickBoxColor={tickBoxColor}
          >
            {added && <img src={"/images/check_white.svg"} alt={"✓"} />}
          </S.Tick>
        )}
        {heading}
      </S.Heading>
      <S.Container
        added={added}
        bgColor={bgColor}
        hoverColor={hoverColor}
        addedColor={addedColor}
      >
        {children || noChildren()}
      </S.Container>
    </S.Wrapper>
  );
};

export default ToggleContainerV1;
